import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"

class LiveProject extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Live projects" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-live-project inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center"></h2>
            </div>

            <article className="article project inuit-u-p0">
                <br/><br/>
                <div className="article__body">
                    <h1 className="article__heading">LIVE PROJECTS</h1>
                    <p className="article__para">
                        <h3>Gestion de projet et CRM</h3>
                    </p>
                </div>

                <br /><br />

            </article>

            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default LiveProject
